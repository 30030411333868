/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
// import { DropDown, Menu } from '../components/common/sidebarMenu';
import { UserContext } from "../context/theme";
import ROOT_URL from "../services/api-url";
import authAxios from "../services/authAxios";
import common from "../services/common";

function Header() {
  const { setAuth, setUserInfo } = useContext(UserContext);
  const { userInfo } = useContext(UserContext);
  const role = userInfo?.role;
  // const [isActive, setActive] = useState('false');

  // const ToggleClass = (e) =>{
  //     e.preventDefault();
  //     setActive(!isActive);
  // }
  // eslint-disable-next-line no-unused-vars
  const Logout = () => {
    localStorage.removeItem("token");
    setAuth(false);
  };

  const getProfile = async (id) => {
    await authAxios({
      method: "GET",
      url: `${ROOT_URL}/admins/get/${id}`,
    })
      .then((res) => {
        setUserInfo(res.data.data);
        // navigate('/admin')
      })
      .catch((error) => {
        common.error(error);
      });
  };

  useEffect(() => {
    let userId = reactLocalStorage.get("userId");
    if (userId) {
      getProfile(userId);
    }
  }, []);

  return (
    <>
      <div className="left-sidebar-wrapper">
        <nav className="sidebar">
          <div className="sidebar-header text-center">
            <NavLink activeclassname="isActive" to="/">
              <img
                src={`/assets/images/samskaraLogo.png`}
                className="img-fluid"
                alt="logo"
              />
            </NavLink>

            <strong>
              <NavLink activeclassname="isActive" to="/">
                <img src={`/assets/images/logo-small.png`} alt="logo" />
              </NavLink>
            </strong>
          </div>

          <div className="left-nav-menu">
            {/* ADMIN */}
            <DropDownMenu>
              {/* <Menu
                path={`/country/1`}
                title="Country List"
                icon="fas fa-list"
              /> */}
              {/* <Dropdown icon="fas fa-globe-asia" title="Country">
                <Menu
                path={`/add-country`}
                title="Add Country"
                icon="fas fa-plus"
                />
              </Dropdown> */}
              {role && role !== "BLOG-MANAGER" && <>
                <Menu path="/" title="Dashboard" icon="icon-wrap icon-home" />
                <Dropdown icon="fas fa-users" title="Users">
                  <Menu
                    path={`/customer/1`}
                    title="Customer List"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/serviceProvider/1`}
                    title="Service Provider List"
                    icon="fas fa-list"
                  />
                  {/* <Dropdown icon="fas fa-user-tie" title="Customer">
                  <Menu
                    path={`/add-customer`}
                    title="Add Customer"
                    icon="fas fa-plus"
                  />
                </Dropdown> */}
                  <Menu
                    path={`/vendor/1`}
                    title="Vendor List"
                    icon="fas fa-store"
                  />
                  {/* <Dropdown icon="fas fa-store" title="Vendor">
                  <Menu path={`/add-vendor`} title="Add" icon="fas fa-plus" />
                </Dropdown> */}
                  {role !== "SUB-ADMIN-OPS" ? <Menu path={`/admin/1`} title="Admin List" icon="fas fa-list" /> : <></>}
                  {/* <Dropdown icon="icon-wrap icon-user" title="Admin">
                  <Menu
                    path={`/create-admin`}
                    title="Create"
                    icon="fas fa-plus"
                  />
                </Dropdown> */}
                </Dropdown>

                <Dropdown icon="fas fa-chart-bar" title="Analytics">
                  <Menu
                    path={`/orders/1`}
                    title="Orders List"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/cancelledOrders/1`}
                    title="Cancelled Orders List"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/draftOrders/1`}
                    title="Draft Orders List"
                    icon="fas fa-list"
                  />

                  <Dropdown icon="fas fa-gopuram" title="Event Allotment">
                    <Menu
                      path={`/sp-list/1`}
                      title="Assign SP"
                      icon="fas fa-list"
                    />
                    <Menu
                      path={`/vendor-list/1`}
                      title="Assign Vendor"
                      icon="fas fa-list"
                    />
                  </Dropdown>
                  {/* <Dropdown icon="fas fa-ad" title="Promotion">
                  <Menu
                    path={`/add-promotion`}
                    title="Add"
                    icon="fas fa-plus"
                  />
                </Dropdown> */}
                  <Menu
                    path={`/custom-puja-enquiry/1`}
                    title="Custom Puja Enquiry"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={"/rating/1"}
                    title="Rating List"
                    icon="fas fa-list"
                  />
                </Dropdown>

                {/* payment Approval start */}
                {role !== "SUB-ADMIN-OPS" ? <Dropdown icon="fas fa-money-check" title="Transactions">
                  <Menu path={`/payment/1`} title="Payment " icon="fas fa-list" />
                  <Menu
                    path={`/paymentApproval/1`}
                    title="SP Deposit Approval "
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/transactions/1`}
                    title="Transactions History "
                    icon="fas fa-list"
                  />
                </Dropdown> : <></>}
                {/*  payment Approval end */}

                {role !== "SUB-ADMIN-OPS" ? <Dropdown icon="fas fa-money-check" title="Earnings">
                  <Menu
                    path={`/adminEarnings/1`}
                    title="Admin Earnings"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/spEarnings/1`}
                    title="SP Earnings"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/vendorEarnings/1`}
                    title="Vendor Earnings"
                    icon="fas fa-list"
                  />
                </Dropdown> : <></>}


                {role !== "SUB-ADMIN-OPS" ? <Dropdown icon="fas fa-gopuram" title="Event">
                  {/* <Menu
                  path={`/puja-package/1`}
                  title="Puja-Package List"
                  icon="fas fa-list"
                />
                <Menu
                  path={`/add-puja-package`}
                  title="Add Puja-Package"
                  icon="fas fa-plus"
                /> */}
                  <Menu
                    path={`/puja-category/1`}
                    title="Event Category List"
                    icon="fas fa-list"
                  />

                  <Menu
                    path={`/consultancy-category/1`}
                    title="Consultancy Category List"
                    icon="fas fa-list"
                  />

                  <Menu path={`/puja/1`} title="Event List" icon="fas fa-list" />
                  <Menu path={`/community-puja/1`} title="Community Event List" icon="fas fa-list" />

                  <Menu path={`/consultancy-service/1`} title="Consultancy List" icon="fas fa-list" />
                  {/* <Menu
                  path={`/add-puja-category`}
                  title="Add Event Category"
                  icon="fas fa-plus"
                />
                <Menu path={`/add-puja`} title="Add Event" icon="fas fa-plus" /> */}
                </Dropdown> : <></>}

                {role !== "SUB-ADMIN-OPS" ? <Dropdown icon="fas fa-plus" title="Add-Ons">
                  <Menu
                    path={`/addons-1/1`}
                    title="Pandit Extra List"
                    icon="fas fa-list"
                  />
                  {/* <Dropdown icon="fas fa-dash" title="Pandit Extra">
                  <Menu
                    path={`/add-Addon-1`}
                    title="Add Add-On-1"
                    icon="fas fa-plus"
                  />
                </Dropdown> */}
                  <Menu
                    path={`/addons-2/1`}
                    title="Pandit List"
                    icon="fas fa-list"
                  />
                  {/* <Dropdown icon="fas fa-dash" title="Experience Level">
                  <Menu path={`/add-Addon-2`} title="Add" icon="fas fa-plus" />
                </Dropdown> */}
                  <Menu
                    path={`/addons-3/1`}
                    title="AddOns List"
                    icon="fas fa-list"
                  />
                  {/* <Dropdown icon="fas fa-dash" title="AddOns Type">
                  <Menu path={`/add-Addon-3`} title="Add" icon="fas fa-plus" />
                </Dropdown> */}
                  <Menu
                    path={`/addons-4/1`}
                    title="Inclusion List"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/addons-5/1`}
                    title="Community AddOns List"
                    icon="fas fa-list"
                  />

                  <Menu
                    path={`/addons-6/1`}
                    title="Community Inclusion List"
                    icon="fas fa-list"
                  />

                  <Menu
                    path={`/addons-7/1`}
                    title="Consultancy AddOns List"
                    icon="fas fa-list"
                  />

                  <Menu
                    path={`/addons-8/1`}
                    title="Consultancy Inclusion List"
                    icon="fas fa-list"
                  />
                  {/* <Dropdown icon="fas fa-dash" title="Inclusion">
                  <Menu path={`/add-Addon-4`} title="Add" icon="fas fa-plus" />
                </Dropdown> */}
                </Dropdown> : <></>}

                {/* <Dropdown icon="fas fa-dolly" title="Orders">
                <Menu
                  path={`/add-orders`}
                  title="Add Orders"
                  icon="fas fa-plus"
                />
              </Dropdown> */}
              </>}
              {role && role !== "SUB-ADMIN-OPS" && <Dropdown icon="fas fa-cubes" title="Blog">
                <Menu
                  path={`/category/1`}
                  title="Blog Category List"
                  icon="fas fa-clipboard-list"
                />
                {/* <Dropdown icon="fas fa-clipboard-list" title="Blog Category">
                  <Menu path={`/add-Category`} title="Add" icon="fas fa-plus" />
                </Dropdown> */}
                <Menu path={`/blog/1`} title="Blog List" icon="fas fa-blog" />
                {/* <Dropdown icon="fas fa-blog" title="Blog">
                  <Menu path={`/add-blog`} title="Add" icon="fas fa-plus" />
                </Dropdown> */}
              </Dropdown>}
              {/* <Dropdown icon="fas fa-handshake" title="Policy">
          
              </Dropdown> */}

              {/* <Dropdown icon="fas fa-film" title="Story">
               </Dropdown> */}
              {/* <Dropdown icon="fas fa-question" title="How it work">
                
              </Dropdown> */}
              {role && role !== 'BLOG-MANAGER' && <>
                <Dropdown icon="fas fa-file" title="Pages">
                  {role !== "SUB-ADMIN-OPS" ? <> <Menu
                    path={`/Structure/home`}
                    title="Home"
                    icon="fas fa-plus"
                  />
                    <Menu
                      path={`/promotion/1`}
                      title="Promotion List"
                      icon="fas fa-ad"
                    />

                    <Menu
                      path={`/launch-popup/1`}
                      title="Launch Popup List"
                      icon="fas fa-ad"
                    />

                    <Menu
                      path={`/Structure/contactDetails`}
                      title="Contact Details"
                      icon="fas fa-plus"
                    />
                    <Menu
                      path={`/Structure/advantage`}
                      title="Advantage"
                      icon="fas fa-plus"
                    /> </> : <></>}

                  {/* upcomingFestival start */}
                  <Menu
                    path={`/upcomingFestival/1`}
                    title="Upcoming Festival List"
                    icon="fas fa-bible"
                  />
                  {/* <Dropdown icon="fas fa-bible" title="Upcoming Festival">
                    <Menu
                      path={`/add-upcomingFestival`}
                      title="Add"
                      icon="fas fa-plus"
                    />
                  </Dropdown> */}
                  {/* upcomingFestival end */}
                  {role !== "SUB-ADMIN-OPS" ? <>
                    {/* promocode start */}
                    <Menu
                      path={`/promocode/1`}
                      title="Promocode List"
                      icon="fas fa-percentage"
                    />
                    {/* <Dropdown icon="fas fa-percentage" title="Promocode">
                    <Menu
                      path={`/add-promocode`}
                      title="Add "
                      icon="fas fa-plus"
                    />
                  </Dropdown> */}
                    {/* promocode end */}

                    {/* notification start */}
                    <Menu
                      path={`/notification`}
                      title="Push Notification"
                      icon="fas fa-bell"
                    />
                    {/* notification end */}

                    {/* story start */}
                    <Menu path={`/story/1`} title="Story List" icon="fas fa-film" />
                    {/* <Dropdown icon="fas fa-film" title="Story">
                    <Menu path={`/add-story`} title="Add" icon="fas fa-plus" />
                  </Dropdown> */}
                    {/* story end */}
                    {/* policy start */}
                    <Dropdown icon="fas fa-handshake" title="Policy">
                      <Menu
                        path={`/add-privacy`}
                        title="Privacy Policy"
                      // icon="fas fa-plus"
                      />
                      <Menu
                        path={`/add-terms`}
                        title="Terms Of Use"
                      // icon="fas fa-plus"
                      />
                      <Menu
                        path={`/add-cancellation`}
                        title="Refunds & Cancellation"
                      // icon="fas fa-plus"
                      />
                    </Dropdown>
                    {/* policy end */}
                    {/* how it work start */}
                    <Dropdown icon="fas fa-question" title="How it work">
                      {/* <Menu
                      path={`/add-howitwork`}
                      title="Add How It Work"
                      icon="fas fa-plus"
                    /> */}
                      <Menu
                        path={`/howitwork/1`}
                        title="How It Work List"
                        icon="fas fa-list"
                      />
                      {/* <Menu path={`/add-faq`} title="Add Faq" icon="fas fa-plus" /> */}
                      <Menu path={`/faq/1`} title="Faq List" icon="fas fa-list" />
                    </Dropdown>
                    {/* how it work end */}

                    <Menu
                      path={`/testimonial/1`}
                      title="Testimonial List"
                      icon="fas fa-scroll"
                    /></> : <></>}
                  {/* <Dropdown icon="fas fa-scroll" title="Testimonial">
                    <Menu
                      path={`/add-testimonial`}
                      title="Add"
                      icon="fas fa-plus"
                    />
                  </Dropdown> */}
                </Dropdown>

                <Dropdown icon="fas fa-headset" title="Global">
                  {role !== "SUB-ADMIN-OPS" ? <>
                    <Menu path={`/city/1`} title="City List" icon="fas fa-city" />
                    <Menu
                      path={`/experience/1`}
                      title="Experience List"
                      icon="fas fa-anchor"
                    />
                    {/* <Dropdown icon="fas fa-city" title="City">
                    <Menu path={`/add-city`} title="Add" icon="fas fa-plus" />
                  </Dropdown> */}
                    <Menu
                      path={`/pandit-preference/1`}
                      title="Pandit Preference List"
                      icon="fas fa-star"
                    />
                    {/* <Dropdown icon="fas fa-star" title="Pandit Preference">
                    <Menu
                      path={`/add-Pandit-Preference`}
                      title="Add"
                      icon="fas fa-plus"
                    />
                  </Dropdown> */}

                    <Menu
                      path={`/muhurth/1`}
                      title="Muhurth List"
                      icon="fas fa-list"
                    />
                    {/* <Dropdown icon="fas fa-plus" title="Muhurth">
                    <Menu path={`/add-muhurth`} title="Add" icon="fas fa-plus" />
                  </Dropdown> */}
                  </> : <></>}

                  <Menu
                    path={`/contactUs/1`}
                    title="Contact Us List"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/newsLetter/1`}
                    title="News Letter List"
                    icon="fas fa-list"
                  />
                  <Menu
                    path={`/usercallback/1`}
                    title="User Call Back List"
                    icon="fas fa-list"
                  />

                  <Menu
                    path={`/helpDesk/1`}
                    title="HelpDesk List"
                    icon="fas fa-list"
                  />
                  {role !== "SUB-ADMIN-OPS" ?
                    <>
                      <Menu
                        path={`/language/1`}
                        title="Language List"
                        icon="fas fa-language"
                      />
                      <Menu
                        path={`/gstReport/1`}
                        title="GST Report"
                        icon="fas fa-list"
                      />
                    </>


                    : <></>}


                  {/* <Dropdown icon="fas fa-language" title="Language">
                    <Menu path={`/add-language`} title="Add" icon="fas fa-plus" />
                  </Dropdown> */}
                </Dropdown>
              </>}
            </DropDownMenu>
            {/* ADMIN */}
          </div>
          <div className="version">
            <span className="vs-mobile">ER-MAY-22</span>
            <span className="vs-web">
              <div className="version">{common.version()}</div>
            </span>
          </div>
        </nav>
      </div>
    </>
  );
}
export default Header;
